import * as React from 'react';
import classnames from 'classnames';
import $ from './flag.scss';

const FlagClassnames = {
    at: $.at,
    au: $.au,
    ca: $.ca,
    ch: $.ch,
    cz: $.cz,
    de: $.de,
    dk: $.dk,
    es: $.es,
    fr: $.fr,
    ie: $.ie,
    it: $.it,
    nl: $.nl,
    ro: $.ro,
    pl: $.pl,
    se: $.se,
    gb: $.gb,
    us: $.us,
};

type Props = {
    className?: string;
    cultureId?: string; // eg: ca-FR
    countryCode?: string; // eg: ca
};

const Flag: React.FunctionComponent<Props> = (props: Props) => {
    const { cultureId, countryCode } = props;

    const code = countryCode || (cultureId && cultureId.substring(3, 5));
    if (!code) return null;

    const flagClassname = FlagClassnames[code.toLowerCase()];
    if (!flagClassname) return null;

    return <div className={classnames($.flag, flagClassname)} />;
};

export default Flag;
