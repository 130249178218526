import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, HtmlText, Title } from '~source/ui/components';
import { useLabel } from '~source/ui/hooks';
import { TextImageCta } from '~source/core/models';
import classnames from 'classnames';
import $ from './text-image-cta-small.scss';

const TextImageCtaSmall: React.FunctionComponent<TextImageCta> = ({
    backgroundColor,
    color,
    ctaButton,
    image,
    imageAlignment = 'right',
    showBackButton,
    subtitle,
    text,
    title,
    titleFont,
}) => {
    const history = useHistory();

    const smallCardStyle = {
        backgroundColor,
        color,
    };

    const alignments = [
        'bottom',
        'bottomLeft',
        'bottomLeftOverlap',
        'bottomRight',
        'bottomRightOverlap',
        'center',
        'left',
        'leftOverlap',
        'none',
        'right',
        'rightOverlap',
        'top',
        'topLeft',
        'topLeftOverlap',
        'topRight',
        'topRightOverlap',
    ];

    const alignment = alignments
        .filter(align => align === imageAlignment)
        .toString();

    const smallCardClasses: any[] = [$.flex];
    const imageWrapperClasses: any[] = [$.flex];
    const imageClasses: any[] = [];

    switch (alignment) {
        case 'bottom':
            smallCardClasses.push($.flexDirectionColumn);
            imageWrapperClasses.push(
                $.alignItemsCenter,
                $.justifyContentCenter,
            );
            break;
        case 'bottomLeft':
            smallCardClasses.push($.flexDirectionRowReverse);
            imageWrapperClasses.push($.alignItemsEnd, $.justifyContentCenter);
            imageClasses.push($.imageBottom, $.imageLeft);
            break;
        case 'bottomLeftOverlap':
            smallCardClasses.push($.flexDirectionRowReverse);
            imageWrapperClasses.push($.alignItemsEnd, $.justifyContentCenter);
            imageClasses.push($.imageBottom, $.imageLeftOverlap);
            break;
        case 'bottomRight':
            smallCardClasses.push($.flexDirectionRow);
            imageWrapperClasses.push(
                $.flexDirectionColumn,
                $.justifyContentEnd,
            );
            imageClasses.push($.imageBottom, $.imageRight);
            break;
        case 'bottomRightOverlap':
            smallCardClasses.push($.flexDirectionRow);
            imageWrapperClasses.push(
                $.flexDirectionColumn,
                $.justifyContentEnd,
            );
            imageClasses.push($.imageBottom, $.imageRightOverlap);
            break;
        case 'center':
            smallCardClasses.push($.flexDirectionColumn);
            imageWrapperClasses.push($.alignItemsEnd, $.justifyContentCenter);
            break;
        case 'left':
            smallCardClasses.push($.flexDirectionRowReverse);
            imageWrapperClasses.push(
                $.alignItemsCenter,
                $.justifyContentCenter,
            );
            imageClasses.push($.imageLeft);
            break;
        case 'leftOverlap':
            smallCardClasses.push($.flexDirectionRowReverse);
            imageWrapperClasses.push(
                $.alignItemsCenter,
                $.justifyContentCenter,
            );
            imageClasses.push($.imageLeftOverlap);
            break;
        case 'right':
            smallCardClasses.push($.flexDirectionRow);
            imageWrapperClasses.push(
                $.alignItemsCenter,
                $.justifyContentCenter,
            );
            imageClasses.push($.imageRight);
            break;
        case 'rightOverlap':
            smallCardClasses.push($.flexDirectionRow);
            imageWrapperClasses.push(
                $.alignItemsCenter,
                $.justifyContentCenter,
            );
            imageClasses.push($.imageRightOverlap);
            break;
        case 'top':
            smallCardClasses.push($.flexDirectionColumnReverse);
            imageWrapperClasses.push(
                $.alignItemsCenter,
                $.justifyContentCenter,
            );
            imageClasses.push($.imageTop);
            break;
        case 'topLeft':
            smallCardClasses.push($.flexDirectionRowReverse);
            imageWrapperClasses.push($.alignItemsStart, $.justifyContentStart);
            imageClasses.push($.imageTop, $.imageLeft);
            break;
        case 'topLeftOverlap':
            smallCardClasses.push($.flexDirectionRowReverse);
            imageWrapperClasses.push($.alignItemsStart, $.justifyContentStart);
            imageClasses.push($.imageTop, $.imageLeftOverlap);
            break;
        case 'topRight':
            smallCardClasses.push($.flexDirectionRow);
            imageWrapperClasses.push($.alignItemsStart, $.justifyContentStart);
            imageClasses.push($.imageTop, $.imageRight);
            break;
        case 'topRightOverlap':
            smallCardClasses.push($.flexDirectionRow);
            imageWrapperClasses.push($.alignItemsStart, $.justifyContentStart);
            imageClasses.push($.imageTop, $.imageRightOverlap);
            break;
        case 'none':
        default:
            smallCardClasses.push($.flexDirectionRow);
            imageWrapperClasses.push(
                $.alignItemsCenter,
                $.justifyContentCenter,
            );
            break;
    }

    const component = (
        <div
            className={classnames($.smallCard, smallCardClasses)}
            style={smallCardStyle}
        >
            <div className={$.textWrapper}>
                {subtitle && <Title title={title} titleFont={titleFont} />}
                {text && <HtmlText className={$.text} content={text} />}
            </div>
            {image && image.title && image.url && (
                <div
                    className={classnames($.imageWrapper, imageWrapperClasses)}
                >
                    <img
                        className={classnames($.image, imageClasses)}
                        src={image.url}
                        alt={image.title}
                    />
                </div>
            )}
        </div>
    );

    return (
        <section className={$.root}>
            {ctaButton ? (
                <a
                    href={ctaButton.url}
                    target={ctaButton.openInNewTab ? '_blank' : '_self'}
                >
                    {component}
                </a>
            ) : (
                component
            )}

            {(showBackButton || ctaButton) && (
                <div className={$.buttonWrapper}>
                    {showBackButton && (
                        <Button
                            className={$.buttonBack}
                            color="inverted"
                            icon="chevron-left-blue"
                            iconFirst
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            {useLabel('SmallCard/LastStep')}
                        </Button>
                    )}
                    {ctaButton && (
                        <Button
                            className={$.buttonCta}
                            to={ctaButton.url}
                            openInNewTab={ctaButton.openInNewTab}
                        >
                            {ctaButton.title}
                        </Button>
                    )}
                </div>
            )}
        </section>
    );
};

export default TextImageCtaSmall;
