import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon,
} from 'react-share';
import Page from '~source/core/models/page';
import { ArticleTeaser, Carousel, Container, Title } from '..';
import ModuleHandler from '../module-handler';
import $ from './article.scss';
import useLabel from '~source/ui/hooks/use-label';
import { InspirationPackage } from '~source/core/models';
import { ModuleWrapper } from '../modules';
import CardInspiration from '../card-inspiration';
import Analytics, { tracking } from '~source/core/services/analytics';

type Props = Page;

const Article: React.FunctionComponent<Props> = props => {
    const {
        author,
        header,
        introduction,
        modules,
        otherPackagesCarousel,
        publishTime,
        relatedItems,
        title,
    } = props;
    const history = useHistory();
    const labels = {
        package: useLabel('Inspiration/DiscoverPackage'),
        webshop: useLabel('General/Webshop'),
        otherNews: useLabel('Article/OtherNews'),
        back: useLabel('Article/Back'),
        readAlso: useLabel('Article/ReadAlso'),
        writtenBy: useLabel('Article/WrittenBy'),
        sharing: useLabel('Article/Sharing'),
    };

    const shareUrl = window.location.href;
    const withBackButton = !header || !header.linkLeft;

    const goBack = () => {
        history.goBack();
    };

    function onClickHandler(pkg: InspirationPackage, type: 'cta' | 'webshop') {
        switch (type) {
            default:
            case 'cta': {
                Analytics.event(tracking.events.cta, 'events', {
                    category: tracking.categories.inspiration,
                    action: pkg.lookbookLink.englishTitle,
                    label: `${pkg.analyticsTitle} | ${pkg.analyticsSubtitle}`,
                });
            }
            case 'webshop': {
                Analytics.event(tracking.events.redirect, 'events', {
                    category: tracking.categories.inspiration,
                    action: pkg.productsLink.englishTitle,
                    label: `${pkg.analyticsTitle} | ${pkg.analyticsSubtitle}`,
                });
            }
        }
    }

    function onShareClickHandler(
        platform: 'facebook' | 'whatsapp' | 'twitter' | 'linkedin' | 'email',
    ) {
        const { host, pathname, hash } = window.location;
        Analytics.event(tracking.events.redirect, 'events', {
            category: tracking.categories.flowerTales,
            action: 'sharing',
            label: `${platform} | ${host}${pathname}${hash}`,
        });
    }

    return (
        <>
            <article className={$.article}>
                <Container theme="slim" className={$.container}>
                    <header
                        className={cx($.header, {
                            [$.headerSpacing as string]: !withBackButton,
                        })}
                    >
                        {!!title && (
                            <h1
                                className={cx($.title, {
                                    // If the page has a header (inspiration pages), then the title should color black ¯\_(ツ)_/¯
                                    [$.titleBlack as string]: !!header,
                                })}
                            >
                                {title}
                            </h1>
                        )}
                        {!!publishTime && (
                            <h2 className={$.date}>{publishTime}</h2>
                        )}
                        {/* No back button in header detected? */}
                        {withBackButton && (
                            <small className={$.topBar}>
                                <button
                                    onClick={goBack}
                                    type="button"
                                    className={$.button}
                                >
                                    {labels.back}
                                </button>

                                {author && author.name && (
                                    <span className={$.author}>
                                        {labels.writtenBy} {author.name}
                                    </span>
                                )}
                            </small>
                        )}
                    </header>

                    {!!introduction && (
                        <p className={$.introduction}>{introduction}</p>
                    )}

                    {modules && modules.length > 0 && (
                        <div className={$.moduleContainer}>
                            <ModuleHandler modules={modules} />
                        </div>
                    )}

                    <footer className={$.shareContainer}>
                        <h3 className={$.shareTitle}>{labels.sharing}</h3>

                        <div className={$.shareButtons}>
                            <FacebookShareButton
                                onClick={() => onShareClickHandler('facebook')}
                                url={shareUrl}
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <WhatsappShareButton
                                onClick={() => onShareClickHandler('whatsapp')}
                                url={shareUrl}
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <TwitterShareButton
                                onClick={() => onShareClickHandler('twitter')}
                                url={shareUrl}
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <LinkedinShareButton
                                onClick={() => onShareClickHandler('linkedin')}
                                url={shareUrl}
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>

                            <EmailShareButton
                                onClick={() => onShareClickHandler('email')}
                                url={shareUrl}
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>
                    </footer>
                </Container>
            </article>
            {otherPackagesCarousel && (
                <section>
                    <ModuleWrapper>
                        <>
                            <Title
                                title={otherPackagesCarousel.title}
                                subtitle={otherPackagesCarousel.topTitle}
                            />
                            <Carousel
                                className={$.carousel}
                                classNameItem={$.carouselItem}
                                withPager={false}
                            >
                                {otherPackagesCarousel.packages.map(
                                    (pkg, index) => {
                                        if (!pkg.image) return null;
                                        return (
                                            <CardInspiration
                                                key={pkg.id}
                                                heightPercentageOfWidth={120}
                                                imageSrc={pkg.image}
                                                imageWidth={460}
                                                linkPrimary={{
                                                    ...pkg.lookbookLink,
                                                    title: labels.package,
                                                }}
                                                onPrimaryClick={() =>
                                                    onClickHandler(pkg, 'cta')
                                                }
                                                onSecondaryClick={() =>
                                                    onClickHandler(
                                                        pkg,
                                                        'webshop',
                                                    )
                                                }
                                                linkSecondary={{
                                                    ...pkg.productsLink,
                                                    title: labels.webshop,
                                                    openInNewTab: true,
                                                }}
                                                size="large"
                                                tag={pkg.timeLeft}
                                                title={pkg.title}
                                                topTitle={pkg.subtitle}
                                            />
                                        );
                                    },
                                )}
                            </Carousel>
                        </>
                    </ModuleWrapper>
                </section>
            )}
            {!otherPackagesCarousel && relatedItems && relatedItems.length > 0 && (
                <section>
                    <ModuleWrapper>
                        <>
                            <Title
                                title={labels.otherNews}
                                subtitle={labels.readAlso}
                            />
                            <section className={$.relatedScroll}>
                                <div className={$.relatedContainer}>
                                    {relatedItems &&
                                        relatedItems.map(item => (
                                            <ArticleTeaser
                                                key={item.url}
                                                {...item}
                                                layout="card"
                                                className={$.relatedArticle}
                                                size="small"
                                            />
                                        ))}
                                </div>
                            </section>
                        </>
                    </ModuleWrapper>
                </section>
            )}
        </>
    );
};

export default Article;
