import { transformPage } from '~source/core/transforms';
import endpoints from '~source/core/constants/endpoints';
import fetchUrl from '~source/core/services/fetch-url';
import checkResponse from '~source/utils/check-response';
import { ConfigContextType } from '~source/ui/context/config-context';

export default async function fetchPage(config: ConfigContextType) {
    const endpoint = config.apiUrl + endpoints.page;
    const params = {
        referrer: window.location.href,
        browserCulture: navigator.language || navigator.userLanguage,
        culture: config.cultureId || undefined,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await fetchUrl<any>({
        endpoint,
        params,
    });

    // if page
    if (checkResponse(['pageResponse'], response) && response.page) {
        return transformPage(response.page);
    }

    // error
    return null;
}
