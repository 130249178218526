import React from 'react';
import classnames from 'classnames';
import $ from './container.scss';

type Theme = 'slim' | 'medium' | 'wide';

interface Props {
    children?: React.ReactNode;
    className?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: any;
    fullWidth?: boolean;
    theme?: Theme;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [prop: string]: any;
}

const Container: React.SFC<Props> = ({
    children,
    className,
    fullWidth,
    theme,
    as: ElementType,
    ...rest
}: Props) => {
    const containerClasses = classnames(
        $.container,
        fullWidth && $.isFull,
        theme === 'slim' && $.isSlim,
        theme === 'medium' && $.isMedium,
        theme === 'wide' && $.isWide,
        className,
    );

    return (
        <ElementType className={containerClasses} {...rest}>
            {children}
        </ElementType>
    );
};

Container.defaultProps = {
    as: 'div',
};

export default Container;
