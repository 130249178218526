import * as React from 'react';
import classnames from 'classnames';
import GoogleMapReact from 'google-map-react';
import $ from './map.scss';
import { ConfigContext } from '~source/ui/context';
import { Button } from '~source/ui/components';
import { Map as MapType } from '~source/core/models';
import useLabel from '~source/ui/hooks/use-label';

interface Props extends MapType {
    className?: string;
}

const Map: React.FunctionComponent<Props> = ({
    className,
    lat,
    lng,
}: Props) => {
    const config = React.useContext(ConfigContext);
    const center = { lat, lng };
    const options = {
        fullscreenControl: false,
        zoomControl: false,
    };
    const buttonLabel = useLabel('Contact/MapsRoute');

    const renderMarker = (map, maps) => {
        // eslint-disable-next-line no-new
        new maps.Marker({
            map,
            position: new maps.LatLng(lat, lng),
        });
    };

    return (
        <section className={classnames($.map, className)}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: config.mapsApiKey }}
                center={center}
                zoom={14}
                options={options}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
            />
            <Button
                className={$.button}
                to={`http://www.google.com/maps/place/${lat},${lng}`}
            >
                {buttonLabel}
                <img
                    className={$.buttonIcon}
                    src="/static/icons/navigation.svg"
                    alt=""
                />
            </Button>
        </section>
    );
};

export default Map;
