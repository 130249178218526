import React from 'react';
import classnames from 'classnames';
import { InputHint } from '~source/ui/components';
import $ from './input.scss';

interface InputProps {
    errorMessage?: string;
    hint?: boolean;
    hintText?: string;
    onChange?: (el) => void;
    placeholder?: string;
    required?: boolean;
    type?: 'text' | 'textarea' | 'select' | 'password';
    valid?: boolean;
    value?: string;
    values?: {
        value: string;
        title: string;
    }[];
    viewable?: boolean;
    minHeight?: string;
}

const Input: React.FunctionComponent<InputProps> = ({
    errorMessage,
    hint = false,
    hintText = '',
    minHeight,
    onChange,
    placeholder,
    required,
    type = 'text',
    valid,
    value,
    values,
    viewable = false,
}) => {
    const inputValue = value || '';
    const [isBlurred, setIsBlurred] = React.useState<boolean>(false);
    const [showValue, setShowValue] = React.useState<boolean>(false);

    const wrapClass = classnames([
        $.wrap,
        required && $.wrapRequired,
        isBlurred && valid === false && $.isInvalid,
    ]);
    const inputClass = classnames([
        $.input,
        type === 'text' && $.textfield,
        type === 'textarea' && $.textarea,
        type === 'select' && $.select,
        type === 'password' && $.password,
        isBlurred && valid === false && $.inputInvalid,
    ]);

    const onBlur = () => {
        setIsBlurred(true);
    };

    return (
        <section className={wrapClass}>
            {type === 'text' && (
                <input
                    className={inputClass}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={placeholder}
                    type="text"
                    value={inputValue}
                />
            )}
            {type === 'password' && (
                <>
                    <input
                        className={inputClass}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={placeholder}
                        type={showValue ? 'text' : 'password'}
                        value={inputValue}
                    />
                    {viewable && (
                        <div
                            className={classnames(
                                $.passwordView,
                                showValue && $.active,
                            )}
                            onClick={() => setShowValue(!showValue)}
                        />
                    )}
                    {hint && hintText && (
                        <InputHint
                            label={hintText}
                            className={$.passwordHint}
                        />
                    )}
                </>
            )}
            {type === 'textarea' && (
                <textarea
                    className={inputClass}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={placeholder}
                    value={inputValue}
                    style={minHeight ? { minHeight } : {}}
                />
            )}
            {type === 'select' && (
                <select
                    className={inputClass}
                    onBlur={onBlur}
                    onChange={onChange}
                    defaultValue={inputValue}
                >
                    {values &&
                        values.map(val => (
                            <option key={val.value} value={val.value}>
                                {val.title}
                            </option>
                        ))}
                </select>
            )}
            {isBlurred && valid === false && errorMessage && (
                <span className={$.invalidMessage}>{errorMessage}</span>
            )}
        </section>
    );
};

export default Input;
