import React from 'react';
import classnames from 'classnames';
import { Module } from '~source/core/models';
import {
    ArticleGrid,
    Contact,
    Featured,
    Form,
    Html,
    ImageModule,
    ImagePin,
    InspirationGrid,
    ScrollToAnchor,
    Services,
    Split,
    Text,
    TextImage,
    TextImageCta,
    UspList,
    Vacancies,
    Video,
} from '~source/ui/components';
import { moduleLabels } from '~source/core/constants/moduleTypes';
import { ModuleContext } from '~source/ui/context';
import $ from './module-handler.scss';

interface Props {
    className?: string;
    modules: Module[];
    title?: string;
    useContainer?: boolean;
}

const renderModule = (module: Module) => {
    switch (module.type) {
        case moduleLabels.featured:
            return <Featured {...module} />;
        case moduleLabels.image:
            return <ImageModule {...module} />;
        case moduleLabels.html:
            return <Html {...module} />;
        case moduleLabels.text:
            return <Text {...module} />;
        case moduleLabels.textImage:
            return <TextImage {...module} />;
        case moduleLabels.textImageCta:
            return <TextImageCta {...module} />;
        case moduleLabels.video:
            return <Video {...module} />;
        case moduleLabels.services:
            return <Services {...module} />;
        case moduleLabels.uspList:
            return <UspList {...module} />;
        case moduleLabels.articleGrid:
            return <ArticleGrid {...module} />;
        case moduleLabels.form:
            return <Form {...module} />;
        case moduleLabels.contact:
            return <Contact {...module} />;
        case moduleLabels.vacancies:
            return <Vacancies {...module} />;
        case moduleLabels.split:
            return <Split {...module} />;
        case moduleLabels.imagePin:
            return <ImagePin {...module} />;
        case moduleLabels.inspirationPackage:
            return <InspirationGrid {...module} />;
        default:
            return null;
    }
};

const ModuleHandler = ({ className, modules, useContainer = true }: Props) => (
    <ModuleContext.Provider value={{ useContainer }}>
        <section className={classnames($.moduleHandler, className)}>
            {modules.map((module: Module) => (
                <ScrollToAnchor key={module.id} id={module.id}>
                    {renderModule(module)}
                </ScrollToAnchor>
            ))}
        </section>
    </ModuleContext.Provider>
);

export default ModuleHandler;
