import React from 'react';
import { Link } from 'react-router-dom';

import { InitContext } from '~source/ui/context';
import useLabel from '~source/ui/hooks/use-label';
import { Notification as NotificationModel } from '~source/core/models';
import $ from './notification.module.scss';

const Notification = () => {
    const init = React.useContext(InitContext);
    const labels = {
        browserNotSupported: useLabel('Notifications/Browser/NotSupported'),
    };

    // An array of notifications.
    const [notifications, setNotifications] = React.useState<
        NotificationModel[]
    >([]);

    // Checks for Internet Explorer 8.0 and newer.
    // Adding a space after the text to make the Link node look like inline text.
    // id has to be unique.
    React.useEffect(() => {
        if (window.document.documentMode && init) {
            const setHtml = () => {
                return { __html: labels.browserNotSupported };
            };
            const {
                whiteLabel: { ieNotSupportedLink },
            } = init;

            setNotifications([
                {
                    id: 'browser-not-supported',
                    text: '',
                    node: (
                        <>
                            <div
                                className={$.node}
                                dangerouslySetInnerHTML={setHtml()}
                            />
                            {ieNotSupportedLink && (
                                <Link
                                    className={$.link}
                                    to={ieNotSupportedLink.url}
                                >
                                    {ieNotSupportedLink.title}
                                </Link>
                            )}
                        </>
                    ),
                },
            ]);
        }
    }, [labels.browserNotSupported, init]);

    // Return an array of all notifications minus the one clicked.
    const removeNotification = (id: string) => {
        const filteredNotifications = notifications.filter(
            notification => notification.id !== id,
        );
        setNotifications(filteredNotifications);
    };

    // Don't render if there are no notifications to show after useEffect.
    if (notifications.length <= 0 || !init) return null;

    // The notification text and node need a wrapping container to adhere to flexbox styles
    // when only one of the two is displayed (text or node).
    return (
        <div className={$.root}>
            {notifications.map(notification => (
                <div className={$.notification} key={notification.id}>
                    {(notification.text || notification.node) && (
                        <div className={$.flexWrap}>
                            {notification.text}
                            {notification.node}
                        </div>
                    )}
                    <button
                        type="button"
                        className={$.removeButton}
                        onClick={() => removeNotification(notification.id)}
                    />
                </div>
            ))}
        </div>
    );
};

export default Notification;
