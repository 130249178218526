import { pick } from 'lodash';
import { Contact } from '../models';
import { isString } from '~source/utils/check-type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformContact(data: Entity): Contact {
    if (!data) throw new Error('Contact module: no data');

    const { id, model } = data;

    const openingHours =
        model &&
        model.openingHours &&
        model.openingHours.map((item: any) => {
            return {
                day: item[0],
                time: item[1],
            };
        });

    const contactInfo: Contact['contactInfo'] = {
        title: model && model.title,
        visitAddress:
            model && model.address && model.address.replace('\\n', '<br />'),
        postAddress:
            model &&
            model.postAddress &&
            model.postAddress.replace('\\n', '<br />'),
        phone: model && model.phoneNumber,
        fax: model && model.faxNumber,
        email: model && model.email,
        header: model && model.header,
    };

    if (model && model.branchManager) {
        contactInfo.person = {
            name:
                model.branchManager.name && isString(model.branchManager.name),
            image:
                model.branchManager.photo &&
                pick(model.branchManager.photo, ['url', 'title']),
        };
    }

    let map: Contact['map'];
    if (model.latitude && model.longitude) {
        map = {
            lat: Number(model.latitude),
            lng: Number(model.longitude),
        };
    }

    return {
        id,
        type: 'contact',
        openingHours,
        contactInfo,
        map,
    };
}
