import React from 'react';
import $ from './contact-form.scss';
import { Form as FormModel } from '~source/core/models';
import { Button, Input, Title, HtmlText } from '~source/ui/components';
import useLabel from '~source/ui/hooks/use-label';
import useFormValue from '~source/ui/hooks/use-form-value';
import { isEmail, isRequired } from '~source/utils/form-validation';
import { SuccessMessage } from '~source/ui/components/modules';

export interface FormData {
    name: string;
    email: string;
    message: string;
}

export interface Props {
    body: string;
    subtitle: string;
    title?: string;
    onSubmit: (data: FormData) => void;
    success: boolean;
    setSuccess: (isSuccess: boolean) => void;
}

const ContactForm: React.FunctionComponent<Props> = ({
    body,
    onSubmit,
    setSuccess,
    subtitle,
    success,
    title,
}: Props & FormModel) => {
    const [formValid, setFormValid] = React.useState(false);
    const name = useFormValue('', isRequired);
    const email = useFormValue('', isEmail);
    const messageValidation = value => isRequired(value, 20);
    const message = useFormValue('', messageValidation);

    const labels = {
        name: useLabel('Form/Contact/Name'),
        email: useLabel('Form/Contact/Email'),
        message: useLabel('Form/Contact/Message'),
        submit: useLabel('Form/Contact/Submit'),
        invalidEmail: useLabel('Form/Contact/InvalidEmail'),
        invalidMessage: useLabel('Form/Contact/InvalidMessage'),
        backButton: useLabel('Form/Contact/BackButton'),
        successMessage: useLabel('Form/Contact/SuccessMessage'),
    };

    React.useEffect(() => {
        const isValid = name.valid && email.valid && message.valid;
        setFormValid(isValid);
    }, [name, email, message]);

    const resetForm = () => {
        name.setValue('');
        email.setValue('');
        message.setValue('');
        setFormValid(false);
        setSuccess(false);
    };

    const submitHandler = React.useCallback(
        e => {
            e.preventDefault();

            const formData = {
                name: name.value,
                email: email.value,
                message: message.value,
            };

            onSubmit(formData);
        },
        [email.value, message.value, name.value, onSubmit],
    );

    return (
        <section className={$.wrap}>
            <Title
                title={title}
                subtitle={subtitle}
                className={$.headTitle}
                size="large"
            />
            <HtmlText className={$.headBody} content={body} />
            {!success && (
                <form onSubmit={submitHandler}>
                    <Input
                        type="text"
                        placeholder={labels.name}
                        required
                        value={name.value}
                        valid={name.valid}
                        onChange={name.onChange}
                    />
                    <Input
                        type="text"
                        placeholder={labels.email}
                        required
                        value={email.value}
                        valid={email.valid}
                        errorMessage={labels.invalidEmail}
                        onChange={email.onChange}
                    />
                    <Input
                        type="textarea"
                        placeholder={labels.message}
                        required
                        value={message.value}
                        valid={message.valid}
                        errorMessage={labels.invalidMessage}
                        onChange={message.onChange}
                    />
                    <Button
                        disabled={formValid === false}
                        className={$.submit}
                        type="submit"
                    >
                        {labels.submit}
                    </Button>
                </form>
            )}

            {success && (
                <SuccessMessage
                    message={labels.successMessage}
                    backLabel={labels.backButton}
                    resetForm={resetForm}
                />
            )}
        </section>
    );
};

export default ContactForm;
