import React from 'react';
import { TextImageCta as TextImageCtaType } from '~source/core/models';
import { Container, Card, Image } from '~source/ui/components';
import useImageSize from '~source/ui/hooks/use-image-size';
import $ from './text-image-cta-default.scss';

type Props = TextImageCtaType;

const TextImageCtaDefault: React.FunctionComponent<Props> = (props: Props) => {
    const {
        backgroundColor,
        backgroundImage,
        color,
        showGradient,
        image,
    } = props;
    const style = {
        backgroundColor,
        color,
    };
    const { large: imageSizeLarge } = useImageSize();

    return (
        <section className={$.textImageCtaDefault} style={style}>
            {backgroundImage && backgroundImage.url && (
                <Image
                    placeholder={`${backgroundImage.url}?w=30&q=50`}
                    src={`${backgroundImage.url}?w=${imageSizeLarge}&q=75`}
                    alt={backgroundImage.title}
                    isBackground
                />
            )}
            {backgroundImage && showGradient && <div className="shadow" />}
            <Container className={$.container}>
                <Card {...props} />
            </Container>
            {image && image.url && (
                <Image
                    alt={image.title}
                    className={$.foregroundImage}
                    placeholder={`${image.url}?q=50`}
                    src={`${image.url}?q=75`}
                />
            )}
        </section>
    );
};

export default TextImageCtaDefault;
