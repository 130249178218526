import React from 'react';
import cx from 'classnames';

import { Image, Button, Tag } from '..';
import { Link } from '~source/core/models';
import $ from './card-inspiration.scss';

interface Props {
    className?: string;
    heightPercentageOfWidth: number;
    imageSrc: string;
    imageWidth: number;
    linkPrimary?: Link;
    linkSecondary?: Link;
    onPrimaryClick?: () => void;
    onSecondaryClick?: () => void;
    size: 'small' | 'medium' | 'large';
    tag?: string;
    title?: string;
    topTitle?: string;
}

const CardInspiration: React.FC<Props> = ({
    className,
    heightPercentageOfWidth,
    imageSrc,
    imageWidth,
    linkPrimary,
    linkSecondary,
    onPrimaryClick = () => {},
    onSecondaryClick = () => {},
    size,
    tag,
    title,
    topTitle,
}) => {
    return (
        <article
            className={cx(className, {
                [$.small as string]: size === 'small',
                [$.medium as string]: size === 'medium',
                [$.large as string]: size === 'large',
            })}
        >
            <Image
                alt={title}
                isBackground
                placeholder={`${imageSrc}?w=30&q=50`}
                src={`${imageSrc}?w=${imageWidth}&q=75`}
            />
            {/* Aspect ratio <div /> */}
            <div style={{ paddingTop: `${heightPercentageOfWidth}%` }} />
            {tag && <Tag className={$.tag}>{tag}</Tag>}
            <div className={$.text}>
                {topTitle && <p className={$.label}>{topTitle}</p>}
                {title && <h3 className={$.title}>{title}</h3>}
                {linkPrimary && (
                    <Button
                        className={$.button}
                        onClick={onPrimaryClick}
                        openInNewTab={linkPrimary.openInNewTab}
                        to={linkPrimary.url}
                    >
                        {linkPrimary.title}
                    </Button>
                )}
                {linkSecondary && (
                    <Button
                        className={$.button}
                        color="white-inverted"
                        icon="chevron-right-white"
                        onClick={onSecondaryClick}
                        openInNewTab={linkSecondary.openInNewTab}
                        to={linkSecondary.url}
                    >
                        {linkSecondary.title}
                    </Button>
                )}
            </div>
        </article>
    );
};

export default CardInspiration;
