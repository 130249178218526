/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

interface FetchUrlParams {
    endpoint: string;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    body?: any;
    params?: any;
    headers?: any;
}

export default async function fetchUrl<Entity>(
    { endpoint, body, method = 'GET', params }: FetchUrlParams,
    mustReturnFullResponse: boolean = false,
): Promise<Entity> {
    const options = {
        method,
        data: body ? JSON.stringify(body) : undefined,
        params,
        withCredentials: true, // includes website cookies in the api calls. https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
        headers: body
            ? {
                  'Content-Type': 'application/json',
              }
            : {},
    };
    const url = `${endpoint}`;
    const response = axios(url, options)
        .then(res => {
            if (mustReturnFullResponse) {
                return res;
            }
            return res.data as Entity;
        })
        .catch(error => {
            if (!mustReturnFullResponse && error.response) {
                if (error.response.status === 500) {
                    // TODO: enable if new API structure is ready
                    // window.location.href = '/500';
                }
                return error.response;
            }
            return error;
        });

    return response;
}
