import * as React from 'react';
import { Config } from '~source/core/models';

export type ConfigContextType = Config;

const ConfigContext = React.createContext<Config>({
    apiUrl: '',
    gtmContainerId: '',
    mapsApiKey: '',
    initialCultureId: '', // eg: cs-CZ
    cultureId: '', // eg: cs-CZ
    setCultureId: () => {},
});

export default ConfigContext;
