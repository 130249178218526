import React, { Children, Key } from 'react';
import cx from 'classnames';

import { useLabel } from '~source/ui/hooks';
import $ from './carousel.scss';

interface Props {
    children: React.ReactNode;
    className?: string;
    classNameItem?: string;
    onSlide?: (index: number) => void;
    withPager?: boolean;
}

function getKey(item: React.ReactNode): Key {
    if (typeof item !== 'object') {
        throw new Error(
            `Each item in the carousel must be an ReactElement, got a "${typeof item}"`,
        );
    }
    const { key } = item as any;
    if (!key) {
        throw new Error(
            'Each item in the carousel should have a unique "key" prop',
        );
    }
    return key;
}
function isVisible(
    item: DOMRect | ClientRect,
    container: DOMRect | ClientRect,
) {
    if (item.right < container.left || item.left > container.right) {
        return 0; // not visible
    }
    if (item.left >= container.left && item.right <= container.right) {
        return 1; // 100% visible
    }
    // percentage visible
    if (item.left >= container.left) {
        return (container.right - item.left) / item.width;
    }
    return (item.right - container.left) / item.width;
}

const Carousel: React.FC<Props> = ({
    children,
    className,
    classNameItem,
    onSlide,
    withPager = true,
}) => {
    const [range, setRange] = React.useState(1);
    const [isPrevHidden, setPrevHidden] = React.useState(true);
    const [isNextHidden, setNextHidden] = React.useState(false);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const sliderRef = React.useRef<HTMLDivElement>(null);
    const items = Children.toArray(children);
    const itemRefs = React.useRef<(HTMLDivElement | null)[]>([]);
    const slideIndexRef = React.useRef(0);
    const labels = {
        next: useLabel('General/Next'),
        prev: useLabel('General/Previous'),
    };

    function setItemRef(el: HTMLDivElement | null, i: number) {
        itemRefs.current[i] = el;
    }

    function setNavigation() {
        if (!containerRef.current) return;

        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
        const threshold = 100;

        setPrevHidden(scrollLeft <= threshold);
        setNextHidden(scrollLeft + clientWidth >= scrollWidth - threshold);
    }

    function getInfo() {
        const container = containerRef.current;
        if (!container) {
            throw new Error('Invalid containerRef');
        }
        const containerBounds = container.getBoundingClientRect();
        const slides = itemRefs.current.map((el, i) => {
            if (el === null) {
                throw new Error(`Invalid itemRefs[${i}]`);
            }
            const itemBounds = el.getBoundingClientRect();
            return {
                index: i,
                x: el.offsetLeft,
                visible: isVisible(itemBounds, containerBounds),
            };
        });
        const visible = slides.filter(item => item.visible !== 0);
        const first = visible[0];
        const last = visible[visible.length - 1];
        const perScreen = Math.round(
            visible.length - 2 + first.visible + last.visible,
        );
        return { slides, perScreen, first };
    }

    function slideTo(x: number) {
        const container = containerRef.current;
        if (container === null) {
            return;
        }
        if (container.scrollTo) {
            container.scrollTo({
                top: 0,
                left: x,
                behavior: 'smooth',
            });
        } else {
            // IE11
            container.scrollLeft = x;
        }
    }

    function previous() {
        const { slides, first, perScreen } = getInfo();
        let index = first.index - perScreen;
        if (index < 0) {
            index = 0;
        }
        slideIndexRef.current = index;
        if (onSlide) {
            onSlide(index);
        }
        slideTo(slides[index].x);
    }

    function next() {
        const { slides, first, perScreen } = getInfo();
        const index = first.index + perScreen;
        if (index > slides.length - perScreen) {
            slideTo(range);
        } else {
            slideIndexRef.current = index;
            if (onSlide) {
                onSlide(index);
            }
            slideTo(slides[index].x);
        }
    }

    React.useEffect(() => {
        function detectRange() {
            const slider = sliderRef.current;
            if (slider) {
                setRange(slider.scrollWidth - slider.offsetWidth);
            }
        }
        window.addEventListener('resize', detectRange);
        detectRange();
        return () => window.removeEventListener('resize', detectRange);
    }, [items.length]);

    // Check if navigation need to be visible based on the available range
    React.useEffect(() => {
        if (range === 0 && !isPrevHidden) {
            setPrevHidden(true);
        }
        if (range === 0 && !isNextHidden) {
            setNextHidden(true);
        }
        if (range > 0) {
            setNavigation();
        }
    }, [isNextHidden, isPrevHidden, range]);

    return (
        <div className={cx($.carousel, className)}>
            <div
                className={$.body}
                ref={containerRef}
                // onScroll={debounce(handleScroll, 150)}
            >
                <div ref={sliderRef} className={$.items}>
                    {items.map((item, i) => (
                        <div
                            key={getKey(item)}
                            className={cx($.item, classNameItem)}
                            ref={el => setItemRef(el, i)}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            </div>
            {withPager && (
                <button
                    type="button"
                    className={cx($.pager, $.pagerPrev, {
                        [$.pagerHidden as string]: isPrevHidden,
                    })}
                    onClick={previous}
                >
                    {labels.prev}
                </button>
            )}
            {withPager && (
                <button
                    type="button"
                    className={cx($.pager, $.pagerNext, {
                        [$.pagerHidden as string]: isNextHidden,
                    })}
                    onClick={next}
                >
                    {labels.next}
                </button>
            )}
        </div>
    );
};

export default Carousel;
