import React from 'react';

interface Props {
    children: React.ReactNode;
    id: string;
}

const scrollToConfig = {
    // Threshold telling `<ScrollToAnchor />` when children are scrolled sufficiently into viewport
    threshold: 70,
    // Max times to check if children are scrolled into view
    maxCheckCount: 10,
    // Interval between scroll offset checks in ms
    checkInterval: 100,
};

const ScrollToAnchor: React.FC<Props> = ({ children, id }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    // Store interval event, used to clear interval when needed
    const interval = React.useRef<number | null>(null);
    // Scroll viewport to children?
    const scrollToChildren = window.location.hash === `#${id}`;
    // Is auto scrolling allowed for this component?
    const allowedAutoScroll = React.useRef(true);
    // Times checked if children are scrolled into viewport
    const checkCount = React.useRef(0);

    const isSafe = (offset: number) =>
        Math.abs(offset - scrollToConfig.threshold) <= scrollToConfig.threshold;

    const stopAutoScroll = () => {
        // When user scrolls, stop auto scrolling
        allowedAutoScroll.current = false;
        if (interval.current) {
            clearInterval(interval.current);
        }
    };

    const autoScroll = () => {
        // Stop auto scroll === true? Do not continue
        // Ref is not set? Do not continue
        if (!allowedAutoScroll.current || !ref.current) {
            return;
        }
        // Check max amount of times?
        if (checkCount.current >= scrollToConfig.maxCheckCount) {
            stopAutoScroll();
        }

        const { top } = ref.current.getBoundingClientRect();
        const scrollTo = top - scrollToConfig.threshold - window.pageYOffset;
        // Scrolled into view? Do not continue
        if (isSafe(top)) {
            // Up the check count
            checkCount.current += 1;
            return;
        }
        // Reset check count because page jumped
        checkCount.current = 0;

        window.scrollTo(0, scrollTo);
    };

    const startAutoScroll = () => {
        // Start checking if children are scrolled into view
        // If not auto scroll will keep scrolling till children are visible
        // And will check ${scrollToConfig.maxCheckCount} times if they are in view (in case image start to load and the page jumps)
        interval.current = setInterval(
            autoScroll,
            scrollToConfig.checkInterval,
        );
    };

    React.useEffect(() => {
        // Scroll to children? Add scroll listener for stopping auto scroll
        // Prevents disrupting user scroll event
        if (scrollToChildren) {
            window.addEventListener('wheel', stopAutoScroll, {
                passive: true,
            });
            startAutoScroll();
        } else {
            window.removeEventListener('wheel', stopAutoScroll);
        }
        return () => {
            window.removeEventListener('wheel', stopAutoScroll);
            // Check if interval function is active, clear the interval if it is
            if (interval.current) {
                clearInterval(interval.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollToChildren]);

    if (!scrollToChildren) {
        return <>{children}</>;
    }

    return (
        <div ref={ref} id={id}>
            {children}
        </div>
    );
};

export default ScrollToAnchor;
