import * as React from 'react';
import { Init, Font } from '~source/core/models';
import cssVars from 'css-vars-ponyfill';

const addedFilesCache = new Map<string, boolean>();

export default function useFonts(init: Init | null) {
    const loadFont = (font: Font) => {
        if (!font || !font.url) {
            return;
        }
        const { url } = font;

        if (addedFilesCache.get(url)) return;

        const head = document.head || document.getElementsByTagName('head')[0];
        const fileref = document.createElement('link');
        fileref.setAttribute('rel', 'stylesheet');
        fileref.setAttribute('type', 'text/css');
        fileref.setAttribute('href', url);
        head.appendChild(fileref);

        addedFilesCache.set(url, true);
    };

    const initFont = React.useCallback(async whiteLabel => {
        const {
            primaryFont,
            secondaryFont,
            useItalicsForTopTitle,
        } = whiteLabel;

        // Set CSS variables
        cssVars({
            variables: {
                '--primary-font': (primaryFont && primaryFont.title) || '',
                '--secondary-font':
                    (secondaryFont && secondaryFont.title) || '',
                '--font-style-title': useItalicsForTopTitle
                    ? 'italic'
                    : 'normal',
            },
            watch: true, // https://jhildenbiddle.github.io/css-vars-ponyfill/#/?id=watch
        });

        // Inject in <head>
        [primaryFont, secondaryFont].forEach(f => loadFont(f));
    }, []);

    React.useEffect(() => {
        if (init && init.whiteLabel) {
            initFont(init.whiteLabel);
        }
    }, [init, initFont]);
}
