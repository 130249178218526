import { ArticleTeaser } from '../models';
import { isString } from '~source/utils/check-type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformArticleTeaser(data: Entity): ArticleTeaser {
    if (!data) throw new Error('Services module: no data');

    const title = data.title && isString(data.title);
    const intro = data.introduction && isString(data.introduction);
    const date = data.publishTime && isString(data.publishTime);
    const url = data.url && isString(data.url);
    const thumbnailImage = {
        url:
            data.thumbnailImage &&
            data.thumbnailImage.url &&
            isString(data.thumbnailImage.url),
        title:
            data.thumbnailImage &&
            data.thumbnailImage.title &&
            isString(data.thumbnailImage.title),
    };

    return {
        title,
        intro,
        url,
        date,
        thumbnailImage,
        mainTag: {
            title:
                data.mainTag &&
                data.mainTag.title &&
                isString(data.mainTag.title),
            tabTitle:
                data.mainTag &&
                data.mainTag.tabTitle &&
                isString(data.mainTag.tabTitle),
            color:
                data.mainTag &&
                data.mainTag.color &&
                isString(data.mainTag.color),
            url: data.mainTag && data.mainTag.url && isString(data.mainTag.url),
        },
    };
}
