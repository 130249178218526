import * as React from 'react';
import cssVars from 'css-vars-ponyfill';
import Color from 'color';
import { Init } from '~source/core/models';

export default function useColors(init: Init | null) {
    React.useEffect(() => {
        let primaryBackgroundColor;
        let primaryFontColor;
        let primaryThemeColor;
        let primaryThemeColorLight;
        let secondaryFontColor;
        let secondaryThemeColor;
        let secondaryThemeColorLight;
        let tertiaryFontColor;
        if (init && init.whiteLabel) {
            const { whiteLabel } = init;
            ({
                primaryBackgroundColor,
                primaryFontColor,
                primaryThemeColor,
                primaryThemeColorLight,
                secondaryFontColor,
                secondaryThemeColor,
                secondaryThemeColorLight,
                tertiaryFontColor,
            } = whiteLabel);
        }

        const primaryThemeColorHsl = Color.hsl(primaryThemeColor);
        const primaryThemeColorLighter = primaryThemeColorHsl.lighten(0.2);
        const primaryThemeColorLightest = primaryThemeColorHsl.lighten(0.5);

        const secondaryThemeColorHsl = Color.hsl(secondaryThemeColor);
        const secondaryThemeColorLighter = secondaryThemeColorHsl.lighten(0.2);
        const secondaryThemeColorLightest = secondaryThemeColorHsl.lighten(0.5);

        // use ponyfill to support IE11
        cssVars({
            variables: {
                '--primary-color-background': primaryBackgroundColor,
                '--primary-color-font': primaryFontColor,
                '--primary-color-theme': primaryThemeColor,
                '--primary-color-theme-light': primaryThemeColorLight,
                '--primary-color-theme-lighter': primaryThemeColorLighter,
                '--primary-color-theme-lightest': primaryThemeColorLightest,
                '--secondary-color-font': secondaryFontColor,
                '--secondary-color-theme': secondaryThemeColor,
                '--secondary-color-theme-light': secondaryThemeColorLight,
                '--secondary-color-theme-lighter': secondaryThemeColorLighter,
                '--secondary-color-theme-lightest': secondaryThemeColorLightest,
                '--tertiary-color-font': tertiaryFontColor,
            },
            watch: true, // https://jhildenbiddle.github.io/css-vars-ponyfill/#/?id=watch
        });
    }, [init]);
}
