import React from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import {
    Article,
    Category,
    Container,
    HeaderImage,
    ModuleHandler,
    ModuleWrapper,
    PageHeader,
    PageNotFound,
    Spinner,
} from '../components';
import { usePageService, usePageViews, useWindowWidth } from '~source/ui/hooks';
import { Head, PageNavigation } from '~source/ui/components';
import breakpoints from '~source/core/constants/breakpoints';
import Page from '~source/core/models/page';
import { pageLabels } from '~source/core/constants/pageTypes';

import $ from './page.scss';

function markPrerender(data: Page | null) {
    // Prerender markerd as ready? Return, prerender service is done crawling this page
    if (!data || window.prerenderReady) return;

    let timeoutId: number | null = setTimeout(() => {
        window.prerenderReady = true;
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = null;
    }, 500);
}

interface Props {
    hash: string;
}

const PageView: React.FC<Props> = ({ hash }) => {
    const location = useLocation();
    const { pending, fetchData, data } = usePageService();
    const { trackPage } = usePageViews(data);
    const windowWidth = useWindowWidth();

    React.useEffect(() => {
        fetchData();
    }, [fetchData, location]);

    React.useEffect(() => {
        trackPage();
        markPrerender(data);
    }, [trackPage, data]);

    React.useEffect(() => {
        if (data && data.url) {
            window.history.replaceState(
                null,
                '',
                `${data.url}${location.search}${window.location.hash}`,
            );
        }
    }, [data]);

    if (pending) {
        return (
            <div className={$.spinnerContainer}>
                <Spinner />
            </div>
        );
    }

    if (!data) return <PageNotFound />;

    const {
        header,
        headerImage,
        isLargeHeaderImage,
        modules,
        seoDescription,
        seoKeywords,
        sidebarLinks,
        title,
        type,
    } = data;

    const isPageWithSidebar = type === pageLabels.withSidebar;
    const isMobile = windowWidth < breakpoints.large;
    const Component = isPageWithSidebar && !isMobile ? Container : 'section';
    const ComponentStyle = classnames(
        $.container,
        isPageWithSidebar && $.containerWithSidebar,
    );

    const getPageContent = () => {
        switch (type) {
            case pageLabels.article:
                return <Article {...data} />;
            case pageLabels.category:
                return <Category {...data} />;
            default:
                return (
                    <Component className={ComponentStyle}>
                        <>
                            {sidebarLinks && (
                                <aside className={$.sidebar}>
                                    <ModuleWrapper
                                        className={$.sidebarContainer}
                                    >
                                        <PageNavigation
                                            links={sidebarLinks}
                                            type="vertical"
                                        />
                                    </ModuleWrapper>
                                </aside>
                            )}
                            {modules && modules.length > 0 && (
                                <ModuleHandler
                                    modules={modules}
                                    className={sidebarLinks && $.modules}
                                />
                            )}
                        </>
                    </Component>
                );
        }
    };

    return (
        <>
            <Head
                subTitle={title}
                seoDescription={seoDescription}
                seoKeywords={seoKeywords}
            />
            {headerImage && !header && (
                <HeaderImage
                    image={headerImage}
                    isLargeHeaderImage={isLargeHeaderImage}
                />
            )}
            {headerImage && header && <PageHeader {...header} />}
            {getPageContent()}
        </>
    );
};

export default PageView;
